/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('change-username', {
    parent: 'root',
    url: '/change-username',
    controller: 'acpChangeUsernameSectionCtrl',
    params: {
      successState: null
    },
    templateUrl: 'apps/change-username/templates/acp-change-username.ng.html',
    data: {
      permissions: {
        except: ['hub_user', 'isPaypalLinkedAccount'],
        redirectTo: {
          hub_user: 'dashboard',
          isPaypalLinkedAccount: 'dashboard'
        }
      }
    },
    resolve: {
      $userInfo: /* @ngInject */ function(module, acpSimpleAccountModel) {
        return acpSimpleAccountModel.get();
      },
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.change-username" */ 'apps/change-username').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
